import './App.css';
import HeaderComponent from './common/HeaderComponent';
import SideBarComponent from './common/SideBarComponent';
import BodyComponent from './common/BodyComponent';
import Grid from '@mui/material/Grid';
import React from 'react';

export const AppContext = React.createContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {shared_url: "images/20180628_094254.jpg"};
}

callbackFunction = (childData) => {      
  this.setState({shared_url: childData})
}

render() {
  return (
    <div className="App">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <HeaderComponent />
        </Grid>
        <Grid item xs={2}>
          <SideBarComponent parentCallback = {this.callbackFunction}/>
        </Grid>
        <Grid item xs={10} style={{ display: "flex", alignItems: "flex-start" }}>
          <BodyComponent url = {this.state.shared_url} ></BodyComponent>
        </Grid>
      </Grid>
    </div>
  );
}
}

export default App;
