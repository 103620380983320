import React from 'react'
import '../navigation/MenuComponent.css' 
import '../common/BodyComponent.js'

export default function MenuComponent({title, onClick}){
    
    return (
        <div className="menuItem" onClick={onClick}>
          {title}
        </div>
    )
}