import React from 'react';
import '../common/BodyComponent.css'
import DynamicImage from '../common/DynamicImage'


class BodyComponent extends React.Component {

    render(){
        return (
            <div className="bodyItem"> 
                <DynamicImage url={this.props.url}/>
            </div>
        );
    }
}

export default BodyComponent;