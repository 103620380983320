
import React from 'react';
import '../common/SideBarComponent.css'
import MenuComponent from '../navigation/MenuComponent';

class SideBarComponent extends React.Component {
  
  sendData = (imagePath) => {
    this.props.parentCallback(imagePath);
  }

  render(){

    return(        
    <div className='sideBar'>
    <MenuComponent title="Gerry" onClick={() => this.sendData("images/20180628_174151.jpg")} />
    <MenuComponent title="Shelly" onClick={() => this.sendData("images/20180628_094254.jpg")} />
    <MenuComponent title="Kaden" onClick={() => this.sendData("images/20180629_075056.jpg")} />
    <MenuComponent title="Owen" onClick={() => this.sendData("images/20180630_122456.jpg")} />
    </div>
    )
            
  }
}

export default SideBarComponent;